"use client";

import { ActionIcon, Avatar, Modal, NavLink, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconLanguage } from "@tabler/icons-react";
import { Fragment, type ReactElement } from "react";
import { type TranslationsType } from "@/app/_translations/layout/types";
import {
    ActionIconDesign,
    ActionIconSvgDesign,
} from "@/app/_utils/designs/ActionIcon/design";
import { ModalDesign } from "@/app/_utils/designs/Modal/design";
import { NavLinkContainerListModal } from "@/app/_utils/designs/NavLinkContainerListModal/design";
import { NavLinkListModalDesign } from "@/app/_utils/designs/NavLinkListModal/design";
import { TooltipDesign } from "@/app/_utils/designs/Tooltip/design";
import { LinkComp } from "@/generic-adapters/components/Link/comp";
import { usePathnameHook } from "@/generic-adapters/hooks/usePathname/hook";
import { langs } from "./constants";

export const ButtonLangModalComp = ({
    label,
    modal,
}: TranslationsType["components"]["header"]["nav"]["buttonLangModal"]): ReactElement => {
    const pathname = usePathnameHook();
    const lang = pathname.split("/")[1];

    const [
        opened,
        { open, close },
    ] = useDisclosure(false);

    return (
        <Fragment>
            <Modal
                {...ModalDesign}
                opened={opened}
                onClose={close}
                title={modal.title}
            >
                <div {...NavLinkContainerListModal}>
                    {langs.map((k, i) => {
                        const isoLang = k.ISO_639_1.toLowerCase();

                        return (
                            <NavLink
                                {...NavLinkListModalDesign}
                                key={`navlink-network-${k.label}-${i}`}
                                component={LinkComp}
                                aria-pressed={lang === isoLang}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                tabIndex={lang === isoLang ? -1 : undefined}
                                disabled={lang === isoLang}
                                className="focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-default-800"
                                classNames={{
                                    root: `${lang === isoLang ? "bg-default-950" : "bg-default-900/10"} group rounded text-default-300 hover:text-default-100 hover:bg-default-950 focus-visible:bg-default-950 focus-visible:text-default-100 disabled:text-default-100`,
                                    label: `${lang === isoLang ? "text-default-50" : "text-default-100"} group-hover:text-default-50 group-disabled:text-default-50`,
                                    description: `${lang === isoLang ? "text-default-200/90" : "text-default-300/70"} group-hover:text-default-200/90 group-disabled:text-default-200/90`,
                                }}
                                label={k.label}
                                description={k.description}
                                href={`${pathname.replace(/^\/[^/]+/, `/${isoLang}`)}`}
                                onClick={close}
                                leftSection={
                                    <Avatar
                                        color="var(--default-400)"
                                        radius="xl"
                                    >
                                        {k.ISO_639_1}
                                    </Avatar>
                                }
                            />
                        );
                    })}
                </div>
            </Modal>

            <Tooltip
                label={label}
                {...TooltipDesign}
            >
                <ActionIcon
                    component="button"
                    aria-label={label}
                    className={`${ActionIconDesign.className}`}
                    onClick={open}
                >
                    <IconLanguage {...ActionIconSvgDesign} />
                </ActionIcon>
            </Tooltip>
        </Fragment>
    );
};
