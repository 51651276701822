import(/* webpackMode: "eager", webpackExports: ["ButtonLangModalComp"] */ "/vercel/path0/app/_surfaces/layout/components/Navbar/IconButtons/ButtonLangModal/comp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ButtonNavigationComp"] */ "/vercel/path0/app/_surfaces/layout/components/Navbar/IconButtons/ButtonNavigation/comp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ButtonTerminalModalComp"] */ "/vercel/path0/app/_surfaces/layout/components/Navbar/IconButtons/ButtonTerminalModal/comp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MenuLinkComp"] */ "/vercel/path0/app/_surfaces/layout/components/Navbar/MenuLink/comp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BadgeComp"] */ "/vercel/path0/app/_utils/components/Logo/Badge/comp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/vercel/path0/node_modules/.pnpm/@mantine+core@7.17.3_@mantine+hooks@7.17.3_react@19.1.0__@types+react@19.1.0_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/vercel/path0/node_modules/.pnpm/@mantine+core@7.17.3_@mantine+hooks@7.17.3_react@19.1.0__@types+react@19.1.0_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.5.0_next@15.2.4_react-dom@19.1.0_react@19.1.0__react@19.1.0__react@19.1.0/node_modules/@vercel/analytics/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/next@15.2.4_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.4_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/nextjs-toploader@3.8.16_next@15.2.4_react-dom@19.1.0_react@19.1.0__react@19.1.0__react-_1324e230c9187f3b80fbde5901340963/node_modules/nextjs-toploader/dist/index.js");
