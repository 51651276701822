"use client";

import { ReactElement } from "react";
import { envConfig } from "@/generic-adapters/configs/env/config";
import { usePathnameHook } from "@/generic-adapters/hooks/usePathname/hook";

export const BadgeComp = (): ReactElement => {
    const getLang = usePathnameHook().split("/");
    const lang = getLang[1];

    return (
        <div className="ml-2 hidden rounded-md bg-gradient-to-r from-transparent via-chroma-700 to-transparent p-[1px] xs:flex">
            <div className="text-nowrap rounded-md bg-chroma-900 px-1 pb-[3px] pt-[2px] text-[12px] text-chroma-50">
                <span>{`[${lang}] `}</span>
                <sup>{`${envConfig.PUBLIC_BASE_RENDERING} `}</sup>
                <sub className="xxs:hidden"> {`[deadlock]`}</sub>
                <sub className="hidden xxs:inline-block xs:hidden">{`[xxs]`}</sub>
                <sub className="hidden xs:inline-block smx:hidden">{`[xs]`}</sub>
                <sub className="hidden smx:inline-block sm:hidden">{`[smx]`}</sub>
                <sub className="hidden sm:inline-block md:hidden">{`[sm]`}</sub>
                <sub className="hidden md:inline-block lg:hidden">{`[md]`}</sub>
                <sub className="hidden lg:inline-block xl:hidden">{`[lg]`}</sub>
                <sub className="hidden xl:inline-block 2xl:hidden">{`[xl]`}</sub>
                <sub className="hidden 2xl:inline-block">{`[2xl]`}</sub>
            </div>
        </div>
    );
};
