"use client";

import { ActionIcon, Input, Modal, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconTerminal } from "@tabler/icons-react";
import { Fragment, type ReactElement } from "react";
import { type TranslationsType } from "@/app/_translations/layout/types";
import {
    ActionIconDesign,
    ActionIconSvgDesign,
} from "@/app/_utils/designs/ActionIcon/design";
import { ModalDesign } from "@/app/_utils/designs/Modal/design";
import { TooltipDesign } from "@/app/_utils/designs/Tooltip/design";

export const ButtonTerminalModalComp = ({
    label,
    modal,
}: TranslationsType["components"]["header"]["nav"]["buttonTerminalModal"]): ReactElement => {
    const [
        opened,
        { open, close },
    ] = useDisclosure(false);

    return (
        <Fragment>
            <Modal
                {...ModalDesign}
                opened={opened}
                onClose={close}
                title={modal.title}
                size="lg"
            >
                <Input
                    classNames={{
                        section: "select-none",
                    }}
                    data-autofocus
                    variant="unstyled"
                    leftSection={`rpx>`}
                />
            </Modal>

            <Tooltip
                label={label}
                {...TooltipDesign}
            >
                <ActionIcon
                    aria-label={label}
                    className={`hidden sm:flex ${ActionIconDesign.className}`}
                    onClick={open}
                >
                    <IconTerminal {...ActionIconSvgDesign} />
                </ActionIcon>
            </Tooltip>
        </Fragment>
    );
};
