export const envConfig = {
    BASE_URL: process.env.BASE_URL || "http://localhost:3000",

    PUBLIC_BASE_NAME: process.env.NEXT_PUBLIC_BASE_NAME || "raian",

    PUBLIC_BASE_RENDERING: process.env.NEXT_PUBLIC_BASE_RENDERING || "off",

    PUBLIC_METADATA_AUTHORS_URL:
        process.env.NEXT_PUBLIC_METADATA_AUTHORS_URL ||
        "http://localhost:3000/RAIANPIXELS_USERNAME",

    PUBLIC_TOOL_MMMSHOW_URL:
        process.env.NEXT_PUBLIC_TOOL_MMMSHOW_URL ||
        "http://localhost:3000/mmm-show-me-in",
};
