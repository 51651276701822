"use client";

import { ActionIcon, Modal, NavLink, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconMenu } from "@tabler/icons-react";
import { Fragment, type ReactElement, useEffect } from "react";
import { type TranslationsType } from "@/app/_translations/layout/types";
import {
    ActionIconDesign,
    ActionIconSvgDesign,
} from "@/app/_utils/designs/ActionIcon/design";
import { ModalDesign } from "@/app/_utils/designs/Modal/design";
import { NavLinkContainerListModal } from "@/app/_utils/designs/NavLinkContainerListModal/design";
import { TooltipDesign } from "@/app/_utils/designs/Tooltip/design";
import { type LangType } from "@/app/_utils/types";
import { LinkComp } from "@/generic-adapters/components/Link/comp";
import { usePathnameHook } from "@/generic-adapters/hooks/usePathname/hook";

export const ButtonNavigationComp = ({
    label,
    lang,
    links,
    modal,
}: {
    lang: LangType;
} & TranslationsType["components"]["header"]["nav"]["buttonNavigation"] &
    Pick<
        TranslationsType["components"]["header"]["nav"]["menu"],
        "links"
    >): ReactElement => {
    const pathname = usePathnameHook();

    const [
        opened,
        { open, close },
    ] = useDisclosure(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(min-width: 1024px)");

        const handleResize = (event: MediaQueryListEvent) => {
            if (event.matches && opened) {
                close();
            }
        };

        if (mediaQuery.matches && opened) {
            close();
        }

        mediaQuery.addEventListener("change", handleResize);
        return () => mediaQuery.removeEventListener("change", handleResize);
    }, [
        opened,
        close,
    ]);

    return (
        <Fragment>
            <Modal
                {...ModalDesign}
                classNames={{
                    header: "bg-black",
                    content: "bg-black",
                }}
                opened={opened}
                onClose={close}
                title={modal.title}
                fullScreen
            >
                <div {...NavLinkContainerListModal}>
                    {links.map((k, i) => {
                        const href = `/${lang}${k.href}`;
                        const isActive = href === pathname;

                        return (
                            <NavLink
                                key={`navlink-network-${k}-${i}`}
                                component={LinkComp}
                                aria-pressed={isActive}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                tabIndex={isActive ? -1 : undefined}
                                disabled={isActive}
                                className="focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-chroma-800"
                                classNames={{
                                    root: `${isActive ? "bg-chroma-900/80 text-chroma-50" : "bg-chroma-950/30 text-chroma-200"} group p-4 rounded hover:text-chroma-50 hover:bg-chroma-900/80`,
                                }}
                                label={k.label}
                                href={`/${lang}${k.href}`}
                                onClick={close}
                            />
                        );
                    })}
                </div>
            </Modal>

            <Tooltip
                label={label}
                {...TooltipDesign}
            >
                <ActionIcon
                    aria-label={label}
                    className={`flex lg:hidden ${ActionIconDesign.className}`}
                    onClick={open}
                >
                    <IconMenu {...ActionIconSvgDesign} />
                </ActionIcon>
            </Tooltip>
        </Fragment>
    );
};
