"use client";

import { type ReactElement } from "react";
import { LinkComp } from "@/generic-adapters/components/Link/comp";
import { usePathnameHook } from "@/generic-adapters/hooks/usePathname/hook";
import styles from "./styles.tailwind.module.css";

export const MenuLinkComp = ({
    href,
    label,
    firstLink,
    lastLink,
}: {
    href: string;
    label: string;
    firstLink?: boolean;
    lastLink?: boolean;
}): ReactElement => {
    const pathname = usePathnameHook();
    const isActive = href === pathname;

    return (
        <div
            className={`${firstLink ? "ml-0" : "ml-[13px]"} ${isActive ? styles.active : styles.NavContainerStyled}`}
        >
            {isActive ? (
                <span className="relative cursor-default rounded-sm bg-chroma-950/25 px-1 py-3 outline-none">
                    <span className="relative top-[1px] text-nowrap px-2 font-semibold">
                        {label}
                    </span>
                </span>
            ) : (
                <LinkComp
                    className={`${
                        isActive ? "bg-chroma-950/25" : "bg-transparent"
                    } relative rounded-sm px-1 py-3 outline-none`}
                    href={href}
                >
                    <span className="relative top-[1px] text-nowrap px-2 font-semibold">
                        {label}
                    </span>
                    <div
                        className={`${
                            lastLink ? "opacity-0" : "opacity-100"
                        } absolute -right-[7px] top-2 h-full w-[1px] bg-gradient-to-t from-chroma-800 to-transparent`}
                    />
                </LinkComp>
            )}

            <div
                className={`${styles.NavChildStyled} pointer-events-none -bottom-[8px] bg-gradient-to-r from-chroma-800 via-chroma-200 to-chroma-800`}
            >
                <div className="absolute top-[-1px] h-[1px] w-full bg-gradient-to-r from-transparent via-chroma-200 to-transparent" />
                <div className="absolute top-[1px] h-[1px] w-full bg-gradient-to-r from-transparent via-chroma-200 to-transparent" />
            </div>
        </div>
    );
};
