import { type IconProps } from "@tabler/icons-react";

const className =
    "group border border-chroma-900 bg-chroma-950 hover:border-chroma-800 hover:bg-chroma-900 focus:outline-2 focus:outline-chroma-700";

export const ActionIconDesign = {
    className,
};

const svgClassName =
    "h-[80%] w-[80%] stroke-chroma-300 stroke-[1.5] group-hover:stroke-chroma-100";

export const ActionIconSvgDesign: IconProps = {
    className: svgClassName,
    "aria-hidden": "true",
};
