export const langs = [
    {
        label: "English",
        description:
            "For English-speaking users in the United States, the United Kingdom, Canada, Australia, and other regions where English is widely used.",
        ISO_639_1: "EN",
    },
    {
        label: "Español",
        description:
            "Para usuarios hispanohablantes en España, Latinoamérica y otras regiones donde se habla español.",
        ISO_639_1: "ES",
    },
    {
        label: "Português",
        description:
            "Para falantes de português no Brasil, Portugal e outras regiões de língua portuguesa.",
        ISO_639_1: "PT",
    },
];
