const rootClassName =
    "group rounded bg-neutral-800 text-chroma-300 hover:text-chroma-100 hover:bg-chroma-950";

const descriptionClassName = "group-hover:text-neutral-300";

export const NavLinkListModalDesign = {
    className: "focus:outline-2 focus:outline-chroma-700",
    classNames: {
        root: rootClassName,
        description: descriptionClassName,
    },
};
